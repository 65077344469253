import React from "react"
import { Box, useColorMode } from "@chakra-ui/react"

const TripAdvisorWidget = () => {
  const { colorMode } = useColorMode()

  return (
    <Box
      color="text"
      sx={{
        "#CDSWIDSSP": {
          color: "inherit",
          width: "100% !important",
          div: {
            fontFamily: "body",
          },
          ".widSSPClickWrap": {
            left: 0,
          },
          ".widSSPData": {
            padding: 0,
            bg: colorMode === "dark" ? "dark" : "white",
          },
          ".widSSPBranding": {
            display: "none",
          },
          ".widSSPData .widSSPH11": {
            fontSize: ["lg", "xl"],
            fontFamily: "body",
            fontWeight: "bold",
            my: 2,
            color: colorMode === "dark" ? "white" : "text",
          },
          ".widSSPH18, .widSSPLegal": {
            display: "none",
          },
          ".widSSPSummary": {
            mb: 2,
            color: colorMode === "dark" ? "white" : "text",
          },
          ".widSSPData .widSSPInformation .widSSPPopIdx.widSSPSingle": {
            width: "100%",
            m: 0,
          },
          ".widSSPData .widSSPInformation .widSSPPopIdx.widSSPSingle .widSSPPopIdxData": {
            color: colorMode === "dark" ? "gray.200" : "text",
          },
          ".widSSPData .widSSPInformation .widSSPPopIdx .widSSPPopIdxData": {
            fontFamily: "body",
          },
          ".widSSPData .widSSPTrvlRtng .widSSPOverall": {
            color: colorMode === "dark" ? "gray.200" : "text",
          },
          ".widSSPData .widSSPOneReview .widSSPBullet li span": {
            font: "inherit",
            fontFamily: "body",
            fontSize: "md",
            color: colorMode === "dark" ? "gray.200" : "text",
          },
          ".widSSPData .widSSPAll": {
            display: "flex",
            width: "100%",
            p: 0,
            mt: 6,
          },
          ".widSSPSummary:after": {
            content: "'Xwander Nordic guest reviews'",
            fontFamily: "heading",
            fontWeight: "bold",
            fontSize: ["2xl", null, "3xl"],
            lineHeight: [1.33, null, 1.2],
            letterSpacing: "heading",
          },
          ".widSSPAll #writereview, .widSSPAll #allreviews": {
            display: "inline-block",
            bg: "text",
            color: "white",
            fontFamily: "body",
            fontSize: "md",
            fontWeight: "semibold",
            textDecoration: "none",
            py: 3,
            px: 4,
            borderRadius: "full",
          },
          ".widSSPAll .widSSPWriteReview": {
            borderLeft: "none !important",
            pl: "0 !important",
          },
        },
      }}
    >
      <div id="TA_selfserveprop56" className="TA_selfserveprop">
        <ul id="Ua4XjpFc8hl" className="TA_links TL0ZxnYqXh">
          <li id="TDrbuha" className="vcpsm1m5J">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.tripadvisor.com/Attraction_Review-g189918-d16194057-Reviews-Xwander_Nordic-Ivalo_Lapland.html"
            >
              <img
                src="https://www.tripadvisor.co.uk/img/cdsi/img2/branding/150_logo-11900-2.png"
                alt="TripAdvisor"
              />
            </a>
          </li>
        </ul>
      </div>
    </Box>
  )
}

export default TripAdvisorWidget
