import React from "react"
import { Box, Heading, Text } from "@chakra-ui/react"
import { navigate } from "gatsby"
import Img from "gatsby-image"

const TourCard = ({ title, subTitle, image, slug }) => (
  <Box
    sx={{ position: "relative" }}
    cursor="pointer"
    onClick={(event) => {
      event.preventDefault()
      navigate(slug)
    }}
  >
    <Img fluid={{ ...image.childImageSharp.fluid, aspectRatio: 4 / 3 }} />
    <Box
      p={4}
      sx={{
        position: "absolute",
        right: 0,
        bottom: 0,
        left: 0,
        color: "white",
        backgroundImage: `
            linear-gradient(
              to top,
              hsla(0, 0%, 0%, 0.5) 0%,
              hsla(0, 0%, 0%, 0.494) 8.1%,
              hsla(0, 0%, 0%, 0.476) 15.5%,
              hsla(0, 0%, 0%, 0.448) 22.5%,
              hsla(0, 0%, 0%, 0.412) 29%,
              hsla(0, 0%, 0%, 0.37) 35.3%,
              hsla(0, 0%, 0%, 0.324) 41.2%,
              hsla(0, 0%, 0%, 0.275) 47.1%,
              hsla(0, 0%, 0%, 0.225) 52.9%,
              hsla(0, 0%, 0%, 0.176) 58.8%,
              hsla(0, 0%, 0%, 0.13) 64.7%,
              hsla(0, 0%, 0%, 0.088) 71%,
              hsla(0, 0%, 0%, 0.052) 77.5%,
              hsla(0, 0%, 0%, 0.024) 84.5%,
              hsla(0, 0%, 0%, 0.006) 91.9%,
              hsla(0, 0%, 0%, 0) 100%
            )
            `,
      }}
    >
      <Heading as="h3" size="md" mb={1}>
        {title}
      </Heading>
      <Text>{subTitle}</Text>
    </Box>
  </Box>
)

export default TourCard
