import React, { useContext } from "react"
import { SimpleGrid, Container, Heading, Box } from "@chakra-ui/react"
import Hero from "./hero"
import TourCard from "./tourCard"
import { LocalizationContext } from "../context/LocalizationContext"

export default ({ layouts, lang = "en" }) => {
  const { translate } = useContext(LocalizationContext)
  let blocks = []
  //let reverse = false
  const locale = lang === "en" || lang === "zh" ? "" : `/${lang}`
  layouts.map((layout, i) => {
    switch (layout.fieldGroupName) {
      case "page_Pagebuilder_Layouts_Hero":
        blocks.push(
          <Hero
            image={layout.image.localFile}
            title={layout.heading}
            subTitle={layout.subheading}
            key={i}
          />
        )
        break
      case "page_Pagebuilder_Layouts_TextBlock":
        blocks.push(
          <Box fontSize={["md", "lg", "xl"]} key={i}>
            <Container
              maxWidth="5xl"
              dangerouslySetInnerHTML={{ __html: layout.body }}
            />
          </Box>
        )
        break
      case "page_Pagebuilder_Layouts_TourList":
        blocks.push(
          <Box>
            <Container maxWidth="5xl" key={i}>
              <Heading size="lg" mb={[4, 6]}>
                {translate("headers.tours")}
              </Heading>
              <SimpleGrid columns={[1, 2]} spacing={[4, 6]}>
                {layout.tours.map((tour) => {
                  if (tour) {
                    return (
                      <TourCard
                        key={tour.id}
                        image={tour.featuredImage.node.localFile}
                        title={tour.title}
                        subTitle={tour.tourBuilder.subtitle}
                        slug={`${locale}/${tour.slug}`}
                      />
                    )
                  }
                })}
              </SimpleGrid>
            </Container>
          </Box>
        )
        break
      case "page_Pagebuilder_Layouts_DailyList":
        {
          layout.dailies &&
            blocks.push(
              <Box>
                <Container maxWidth="5xl" key={i}>
                  <Heading size="lg" mb={[4, 6]}>
                    {translate("headers.dailies")}
                  </Heading>
                  <SimpleGrid columns={[1, 2]} spacing={[4, 6]}>
                    {layout.dailies.map((daily) => {
                      if (daily) {
                        return (
                          <TourCard
                            key={daily.id}
                            image={daily.featuredImage.node.localFile}
                            title={daily.title}
                            subTitle={daily.dailyBuilder.subtitle}
                            slug={`${locale}/${daily.slug}`}
                          />
                        )
                      }
                    })}
                  </SimpleGrid>
                </Container>
              </Box>
            )
        }
        break
      default:
        break
    }
  })
  return blocks
}
