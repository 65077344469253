import React, { useState } from "react"
import {
  SimpleGrid,
  Box,
  Heading,
  Button,
  Stack,
  Flex,
  VisuallyHidden,
} from "@chakra-ui/react"
import { useLatestPosts } from "../hooks/useLatestPosts"
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons"
import Post from "./post"

const LatestPosts = ({ language = "en" }) => {
  const allLatestPosts = useLatestPosts()
  const latestPosts = allLatestPosts[language]
  const [index, setIndex] = useState(0)

  if (latestPosts.length === 0) return null

  const length = latestPosts.length - 2
  const handleNext = () =>
    index === length ? setIndex(0) : setIndex(index + 2)
  const handlePrevious = () =>
    index === 0 ? setIndex(length) : setIndex(index - 2)

  const locale = language === "en" ? "" : `/${language}`

  return (
    <Box>
      <Flex justify="space-between">
        <Heading mb={8} size="lg">
          Blog
        </Heading>
        {length > 0 && (
          <Stack direction="row" spacing={0}>
            <Button
              variant="ghost"
              color="gray"
              onClick={() => handlePrevious()}
            >
              <ArrowBackIcon />
              <VisuallyHidden>Previous</VisuallyHidden>
            </Button>
            <Button variant="ghost" color="gray" onClick={() => handleNext()}>
              <ArrowForwardIcon />
              <VisuallyHidden>Next</VisuallyHidden>
            </Button>
          </Stack>
        )}
      </Flex>
      <SimpleGrid columns={[1, 2]} spacing={[4, 6]}>
        <Post
          key={latestPosts[index].node.id}
          title={latestPosts[index].node.title}
          excerpt={latestPosts[index].node.postBuilder.excerpt}
          image={
            latestPosts[index].node.featuredImage
              ? latestPosts[index].node.featuredImage.localFile
              : null
          }
          slug={`${locale}/blog/${latestPosts[index].node.slug}`}
        />
        {latestPosts.length > 1 && (
          <Post
            key={latestPosts[index + 1].node.id}
            title={latestPosts[index + 1].node.title}
            excerpt={latestPosts[index + 1].node.postBuilder.excerpt}
            image={
              latestPosts[index + 1].node.featuredImage
                ? latestPosts[index + 1].node.featuredImage.localFile
                : null
            }
            slug={`${locale}/blog/${latestPosts[index + 1].node.slug}`}
          />
        )}
      </SimpleGrid>
    </Box>
  )
}

export default LatestPosts
