import React, { useContext, useEffect } from "react"
import { Stack, Container, Heading } from "@chakra-ui/react"
import { graphql } from "gatsby"
import { LocalizationContext } from "../context/LocalizationContext"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageBuilder from "../components/pageBuilder"
import Instafeed from "../components/instafeed"
import LatestPosts from "../components/latestPosts"
import Sustainability from "../components/sustainability"
import TripAdvisorWidget from "../components/tripAdvisorWidget"

const IndexPage = ({ data, pageContext }) => {
  const page = data.wpPage
  const language = pageContext.language
  const { setLanguage } = useContext(LocalizationContext)
  setLanguage(language)

  useEffect(() => {
    // and execute it.
    Object.keys(window).forEach((key) => {
      if (key.match(/^injectselfserveprop[0-9]+$/)) {
        window[key]()
      }
    })
  }, [])

  return (
    <Layout language={language} overlayHeader={true}>
      <SEO
        title=""
        lang={language}
        alternates={pageContext.alternates}
        frontpage={true}
      />
      <Stack spacing={24}>
        <PageBuilder
          layouts={page.pageBuilder.layouts}
          lang={page.language.code.toLowerCase()}
        />
      </Stack>

      <Container maxWidth="5xl">
        <Stack spacing={24} mt={24}>
          <Sustainability />
          <LatestPosts language={language} />
          <TripAdvisorWidget />
          <Instafeed />
        </Stack>
      </Container>
      {language === "zh" && (
        <Stack spacing={24} mt={24}>
          <Container maxWidth="5xl">
            <Stack spacing={8}>
              <Heading as="h2" size="xl">
                关注我们的微博
              </Heading>
              <img
                src={"/qr_weibo.png"}
                alt="Weibo QR"
                width="128"
                height="128"
              />
            </Stack>
          </Container>
        </Stack>
      )}
    </Layout>
  )
}
export default IndexPage

export const pageQuery = graphql`
  query($id: String) {
    wpPage(id: { eq: $id }) {
      language {
        code
      }
      title
      pageBuilder {
        layouts {
          ... on WpPage_Pagebuilder_Layouts_Hero {
            fieldGroupName
            heading
            subheading
            image {
              altText
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1600, quality: 60) {
                    ...GatsbyImageSharpFluid
                  }
                }
                colors {
                  vibrant
                  darkVibrant
                  lightVibrant
                  muted
                  darkMuted
                  lightMuted
                }
              }
            }
          }
          ... on WpPage_Pagebuilder_Layouts_TextBlock {
            fieldGroupName
            body
          }
          ... on WpPage_Pagebuilder_Layouts_DailyList {
            fieldGroupName
            dailies {
              ... on WpDaily {
                id
                title
                slug
                dailyBuilder {
                  subtitle
                  excerpt
                  images {
                    image {
                      mediaItemUrl
                    }
                  }
                }
                featuredImage {
                  node {
                    altText
                    sourceUrl
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 484, quality: 60) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                      colors {
                        vibrant
                        darkVibrant
                        lightVibrant
                        muted
                        darkMuted
                        lightMuted
                      }
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Pagebuilder_Layouts_TourList {
            fieldGroupName
            tours {
              ... on WpTour {
                id
                title
                slug
                tourBuilder {
                  subtitle
                  excerpt
                }
                featuredImage {
                  node {
                    altText
                    sourceUrl
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 484, quality: 60) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                      colors {
                        vibrant
                        darkVibrant
                        lightVibrant
                        muted
                        darkMuted
                        lightMuted
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
