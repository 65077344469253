import React from "react"
import { Stack, Box, Heading, Container, Text } from "@chakra-ui/react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default () => {
  const data = useStaticQuery(graphql`
    query InstafeedQuery {
      allInstagramContent(sort: { order: DESC, fields: timestamp }, limit: 6) {
        edges {
          node {
            id
            caption
            localImage {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            permalink
          }
        }
      }
    }
  `)

  const feed = data.allInstagramContent.edges

  return (
    <Box>
      <Heading mb={8} size="lg">
        Xwander Nordic @Instagram
      </Heading>

      <Stack
        direction="row"
        spacing={4}
        sx={{
          overflowX: "auto",
          scrollSnapType: "x mandatory",
        }}
      >
        {feed.map(({ node }, i) => (
          <Box
            as="a"
            href={node.permalink}
            mb={4}
            key={i}
            sx={{
              display: "block",
              minWidth: ["100%", "50%", "33.33333%"],
              scrollSnapAlign: "start",
            }}
          >
            <Img
              fluid={node.localImage.childImageSharp.fluid}
              alt={node.caption}
              sx={{
                display: "block",
                m: 0,
                width: ["100%", null],
                scrollSnapAlign: "center",
              }}
            />
            <Text fontSize="sm" mt={4} noOfLines={6}>
              {node.caption}
            </Text>
          </Box>
        ))}
      </Stack>
    </Box>
  )
}
