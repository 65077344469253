import React, { useContext } from "react"
import { Stack, Box, Button, useColorMode } from "@chakra-ui/react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { LocalizationContext } from "../context/LocalizationContext"
import Img from "gatsby-image"

const Sustainability = () => {
  const { translate, language } = useContext(LocalizationContext)
  const { colorMode } = useColorMode()

  const data = useStaticQuery(graphql`
    query SustainabilityQuery {
      allWpPage(
        limit: 100
        filter: {
          status: { eq: "publish" }
          databaseId: { in: [513, 597, 601] }
        }
      ) {
        edges {
          node {
            id
            slug
            language {
              code
            }
            featuredImage {
              node {
                sourceUrl
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 320) {
                      base64
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                      originalImg
                      originalName
                      presentationWidth
                      presentationHeight
                    }
                  }
                }
              }
            }
            pageBuilder {
              layouts {
                ... on WpPage_Pagebuilder_Layouts_TextBlock {
                  fieldGroupName
                  body
                }
              }
            }
          }
        }
      }
    }
  `)

  const currentPage = data.allWpPage.edges.filter(({ node }) => {
    return node.language.code.toLowerCase() === language
  })[0]

  if (!currentPage) return null

  let body = false
  if (currentPage.node.pageBuilder.layouts) {
    body = currentPage.node.pageBuilder.layouts[0].body.split("<!--more-->")
  }

  if (!body) return null

  return (
    <Stack spacing={4}>
      <Box
        as={Img}
        fluid={
          currentPage.node.featuredImage.node.localFile.childImageSharp.fluid
        }
        maxWidth={240}
        mb={4}
        sx={{
          filter: colorMode === "dark" ? "brightness(0) invert(1)" : "none",
        }}
      />
      <Box
        fontSize={{ md: "lg", lg: "xl" }}
        dangerouslySetInnerHTML={{ __html: body[0] }}
      ></Box>
      <Box>
        <Button variant="link" as={Link} to={`${currentPage.node.slug}`}>
          {translate("tour.readMore")} &rarr;
        </Button>
      </Box>
    </Stack>
  )
}
export default Sustainability
